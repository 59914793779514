// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-code-snippets-js": () => import("./../../../src/pages/code-snippets.js" /* webpackChunkName: "component---src-pages-code-snippets-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mit-js": () => import("./../../../src/pages/mit.js" /* webpackChunkName: "component---src-pages-mit-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-code-snippets-post-js": () => import("./../../../src/templates/code-snippets-post.js" /* webpackChunkName: "component---src-templates-code-snippets-post-js" */),
  "component---src-templates-products-post-js": () => import("./../../../src/templates/products-post.js" /* webpackChunkName: "component---src-templates-products-post-js" */),
  "component---src-templates-works-js": () => import("./../../../src/templates/works.js" /* webpackChunkName: "component---src-templates-works-js" */),
  "component---src-templates-works-post-js": () => import("./../../../src/templates/works-post.js" /* webpackChunkName: "component---src-templates-works-post-js" */)
}

